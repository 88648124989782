<template>
  <div>
    <component ref="model" :is="getComonent(config.modelName||'Model-1')" @ready="modelReady">
      <div class="panoinfo">
        <div style="position:relative">
          <h6 style="margin-right: 20px;">
            {{pano.title}}
          </h6>
          <div class="" style="position:absolute;top:-5px;right:0;padding:5px;cursor:pointer;" @click="$refs.model.hideModal()">
            <i class="fa fa-times"></i>
          </div>
          <div v-if="pano.text" style="max-height:30vh;min-height:30px;overflow:auto">
            <article v-html="pano.text"></article>
          </div>
        </div>
        <div v-if="pano.exif&&!pano.isPro">
          <hr />
          <table class="exif">
            <tr v-if="pano.addTime">
              <th>发布时间：</th>
              <td>{{pano.addTime}}</td>
            </tr>
            <tr v-if="pano.exif.dateTimeOriginal">
              <th>拍摄时间：</th>
              <td>{{pano.exif.dateTimeOriginal}}</td>
            </tr>
            <tr v-if="pano.exif.make">
              <th>相机品牌：</th>
              <td>{{pano.exif.make}}</td>
            </tr>
            <tr v-if="pano.exif.model">
              <th>相机型号：</th>
              <td>{{pano.exif.model}}</td>
            </tr>
            <tr v-if="pano.exif.exposureTime">
              <th>曝光时间：</th>
              <td>{{pano.exif.exposureTime}}</td>
            </tr>
            <tr v-if="pano.exif.fNumber">
              <th>光圈值：</th>
              <td>{{pano.exif.fNumber}}</td>
            </tr>
            <tr v-if="pano.exif.isoSpeedRatings">
              <th>ISO 值：</th>
              <td>{{pano.exif.isoSpeedRatings}}</td>
            </tr>
            <tr v-if="pano.exif.focalLength">
              <th>焦距：</th>
              <td>{{pano.exif.focalLength}}</td>
            </tr>
            <tr v-if="pano.exif.lensModel">
              <th>镜头：</th>
              <td>{{pano.exif.lensModel}}</td>
            </tr>
            <tr v-if="pano.exif.software">
              <th>软件：</th>
              <td>{{pano.exif.software}}</td>
            </tr>
          </table>
        </div>
        <!--<div>
          <button @click="redPark">点击领红包</button>
        </div>-->
      </div>
    </component>
  </div>
</template>
<script>
  export default {
    components: {
    },
    props: {
      config: {
        default() {
          return {}
        }
      },
    },
    data() {
      return {
        modelShow: null,
        modelHide: null,
      }
    },
    inject: {
      publicData: {
        default: {}
      },
      getFunc: {},
      getComonent: {},
    },
    computed: {
      pano() {
        return this.publicData.pano || {}
      },
    },
    created() {
    },
    mounted() {
      this.$emit('ready', {
        vm: this,
        events: {
        },
        funcs: {
          showScreenshot: this.showScreenshot,
          show: this.show,
        }
      })
    },
    destroyed() {
    },
    methods: {
      redPark() {
        this.$axios.get('/Api/Weixin/RedPack/SendRedPack').then((res) => {
          console.log(res)
        })
      },
      modelReady({ funcs }) {
        this.modelShow = funcs.show
        this.modelHide = funcs.hide
      },
      show() {
        if (this.modelShow) {
          this.modelShow()
        } else {
          alert('模态框还没有准备好')
        }
      },
    },
  }
</script>
<style scoped>
  article {
    text-align: justify;
  }

  .panoinfo {
    width: 70vw;
    max-width: 600px;
    min-width: 240px;
    color: #fff;
  }

  .exif {
    font-size: 1.2rem
  }

    .exif tr:first-child {
      white-space: nowrap
    }

    .exif td {
      padding: 0 0 0 0;
      border-bottom: none;
    }

    .exif th {
      padding: 0 0 0 0;
      text-align: right;
      width: 6em;
      vertical-align: top;
      border-bottom: none;
    }
</style>
